import React from 'react';
import ReactDOM from 'react-dom';
import { XIcon } from '../utils';
import { Transition } from '../components';

const Modal = ({
    isShowing = false,
    hide,
    modalTitle,
    modalContent,
    handleClose = hide,
}) =>
    ReactDOM.createPortal(
        <Transition show={isShowing}>
            <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
                {/*Background overlay, show/hide based on modal state.*/}
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75" />
                    </div>
                </Transition.Child>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="block absolute top-0 right-0 pt-3 pr-3">
                            <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-blue-500 transition"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <XIcon className="h-6 w-6 currentColor" />
                            </button>
                        </div>
                        <div className="-mt-10 sm:flex sm:items-start sm:w-80">
                            <div className="flex flex-col w-full">
                                <div className="mt-2 text-left sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3
                                        className="text-lg font-medium text-gray-900 truncate"
                                        id="modal-headline"
                                    >
                                        {modalTitle}
                                    </h3>
                                </div>
                                <div className="mt-2 sm:pl-4">
                                    {modalContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Transition>,
        document.body
    );

export default Modal;
