import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { MainLayout } from '../layouts';
import { Modal, LoadingIndicator, Dropdown } from '../components';
import { reportType, url } from '../constants/report';
import {
    useModal,
    SearchIcon,
    SelectorIcon,
    FilterIcon,
    TrashIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
} from '../utils';
import { useToasts } from 'react-toast-notifications';
// import { Formik, Form, Field } from 'formik';

const types = [
    'blood-report',
    'urine-analysis',
    'biochemistry',
    'haematology',
    'thyroid-profile',
    'other',
];

const Home = () => {
    const { authTokens } = useAuth();
    const history = useHistory();
    const [reports, setReports] = useState([]);
    const [originalReports, setOriginalReports] = useState([]);
    const [totalReports, setTotalReports] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState('blood-report');
    const [currentReport, setCurrentReport] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isShowingTypes, toggleTypes] = useModal();
    const [isShowingDelete, toggleDelete] = useModal();
    const { addToast } = useToasts();

    useEffect(() => {
        const fetchReports = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${url}/api/reports`, {
                    method: 'GET',
                    headers: {
                        Authorization: 'Token ' + authTokens.user.token,
                    },
                });
                const result = await response.json();
                const reports = result.data;
                setTotalReports(result.total);
                setStartIndex(result.startIndex);
                // const sortedReports = [...reports].sort((a, b) =>
                //     b.date.localeCompare(a.date)
                // );
                setReports(reports);
                setOriginalReports(reports);
            } catch (err) {
                addToast('', {
                    appearance: 'error',
                    title: 'Something went wrong! Please try again.',
                });
                console.error(err);
            }
            setIsLoading(false);
        };
        fetchReports();
    }, [authTokens.user.token, addToast]);

    const chooseTypeModal = {
        modalTitle: 'Choose report type',
        modalContent: (
            <>
                <ul className="space-y-4">
                    {types.map((rType) => (
                        <li
                            key={rType}
                            className="relative bg-white rounded-lg cursor-pointer"
                            onClick={() => setType(rType)}
                        >
                            <div className="rounded-lg border border-gray-300 bg-white px-6 py-2 sm:flex sm:justify-between sm:space-x-4 ">
                                <span className="text-sm font-medium text-gray-900">
                                    {reportType[rType]}
                                </span>
                            </div>
                            <div
                                className={` absolute inset-0 rounded-lg border-2 pointer-events-none ${
                                    rType === type
                                        ? 'border-blue-500'
                                        : 'border-transparent'
                                }`}
                            />
                        </li>
                    ))}
                </ul>
                <div className="mt-4 flex items-center justify-center space-x-3">
                    <span className="flex w-full rounded-md shadow-sm">
                        <button
                            type="button"
                            onClick={toggleTypes}
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm transition"
                        >
                            Cancel
                        </button>
                    </span>
                    <span className="flex w-full rounded-md shadow-sm">
                        <button
                            type="button"
                            onClick={() =>
                                history.push({
                                    pathname: `/report/${type}/new`,
                                })
                            }
                            className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm transition"
                        >
                            Continue
                        </button>
                    </span>
                </div>
            </>
        ),
    };

    const searchReports = () => {
        fetch(`${url}/api/reports/?searchTerm=${searchTerm}&startIndex=0`, {
            method: 'GET',
            headers: {
                Authorization: 'Token ' + authTokens.user.token,
            },
        }).then(async (response) => {
            let result = await response.json();
            const reports = result.data;
            // const sortedReports = [...reports].sort((a, b) =>
            //     b.date.localeCompare(a.date)
            // );
            setReports(reports);
            setTotalReports(result.total);
            setStartIndex(result.startIndex);
            setOriginalReports(reports);
        });
    };

    const display = (index) => {
        fetch(
            `${url}/api/reports/?searchTerm=${searchTerm}&startIndex=${index}`,
            {
                method: 'GET',
                headers: {
                    Authorization: 'Token ' + authTokens.user.token,
                },
            }
        ).then(async (response) => {
            let result = await response.json();
            const reports = result.data;
            // const sortedReports = [...reports].sort((a, b) =>
            //     b.date.localeCompare(a.date)
            // );
            setReports(reports);
            setTotalReports(result.total);
            setStartIndex(result.startIndex);
            setOriginalReports(reports);
        });
    };

    const deleteReport = async (id) => {
        try {
            await fetch(`${url}/api/reports/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: 'Token ' + authTokens.user.token,
                },
            });
            const newReports = reports.filter((report) => report._id !== id);
            setReports(newReports);
            toggleDelete();
            addToast('', {
                appearance: 'info',
                title: 'Report deleted.',
            });
        } catch (err) {
            addToast('', {
                appearance: 'error',
                title: 'Something went wrong! Please try again.',
            });
            console.error(err);
        }
    };

    const deleteReportModal = {
        modalTitle: `Delete report?`,
        modalContent: (
            <>
                <p className="text-sm text-gray-500">
                    Are you sure you want to delete this report? This action
                    cannot be undone.
                </p>
                <div className="mt-4 flex items-center justify-center space-x-3">
                    <span className="flex w-full rounded-md shadow-sm">
                        <button
                            type="button"
                            onClick={toggleDelete}
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm transition"
                        >
                            Cancel
                        </button>
                    </span>
                    <span className="flex w-full rounded-md shadow-sm">
                        <button
                            type="button"
                            onClick={() => deleteReport(currentReport._id)}
                            className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm transition"
                        >
                            Delete
                        </button>
                    </span>
                </div>
            </>
        ),
    };

    const handleDeleteReport = (event, report) => {
        event.stopPropagation();
        setCurrentReport(report);
        toggleDelete();
    };

    let enabledClass =
            '-ml-px relative inline-flex items-center space-x-2 px-2 py-2  border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition',
        disabledClass =
            '-ml-px relative inline-flex items-center space-x-2 px-2 py-2  border border-transparent text-base font-medium rounded-full text-white bg-gray-200 focus:outline-none transition';
    const navButtons = (
        <div className="flex items-center justify-center mt-2 py-2 px-2">
            <div
                onClick={() => {
                    startIndex != 0 && display(0);
                }}
                className="px-2 pt-2"
            >
                <button
                    className={startIndex == 0 ? disabledClass : enabledClass}
                >
                    <ChevronDoubleLeftIcon className="h-5 w-5 currentColor" />
                </button>
            </div>
            <div
                onClick={() => {
                    startIndex != 0 && display(startIndex - 200);
                }}
                className="px-2 pt-2"
            >
                <button
                    className={startIndex == 0 ? disabledClass : enabledClass}
                >
                    <ChevronLeftIcon className="h-5 w-5 currentColor" />
                </button>
            </div>
            <div className="px-2 pt-2">
                {startIndex + 1} to{' '}
                {startIndex + 200 > totalReports
                    ? totalReports
                    : startIndex + 200}{' '}
                of {totalReports}
            </div>
            <div
                onClick={() => {
                    startIndex + 200 < totalReports &&
                        display(Number(startIndex) + 200);
                }}
                className="px-2 pt-2"
            >
                <button
                    className={
                        startIndex + 200 < totalReports
                            ? enabledClass
                            : disabledClass
                    }
                >
                    <ChevronRightIcon className="h-5 w-5 currentColor" />
                </button>
            </div>
            <div
                onClick={() => {
                    startIndex + 200 < totalReports &&
                        display(
                            totalReports % 200 == 0
                                ? totalReports - 200
                                : totalReports - (totalReports % 200)
                        );
                }}
                className="px-2 pt-2"
            >
                <button
                    className={
                        startIndex + 200 < totalReports
                            ? enabledClass
                            : disabledClass
                    }
                >
                    <ChevronDoubleRightIcon className="h-5 w-5 currentColor" />
                </button>
            </div>
        </div>
    );

    return (
        <>
            <MainLayout>
                <div className="max-w-none mx-auto">
                    <div className="bg-white overflow-y-visible rounded-lg sm:shadow">
                        <div className="bg-white px-4 py-4 border-b border-gray-200 sm:px-6">
                            <div className="flex flex-col sm:flex-row items-center justify-between flex-wrap sm:flex-nowrap space-y-1 sm:space-y-0">
                                <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:truncate">
                                    Reports
                                </h2>
                                {navButtons}
                                <button
                                    type="button"
                                    onClick={toggleTypes}
                                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
                                >
                                    New report
                                </button>
                            </div>
                        </div>
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <>
                                <div className="flex-1 flex items-center py-3 px-6 border-b border-gray-200 z-1">
                                    <div className="w-full flex md:ml-0">
                                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                            <div className="max-w-xl mx-auto">
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                        <input
                                                            name="searchTerm"
                                                            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                                                            placeholder="Search"
                                                            type="text"
                                                            value={searchTerm}
                                                            onChange={(e) =>
                                                                setSearchTerm(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ==
                                                                    'Enter'
                                                                )
                                                                    return searchReports();
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={searchReports}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            <SearchIcon
                                                                className="h-5 w-5 text-gray-400"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-3 px-1">
                                        {/* Date range picker */}
                                    </div>
                                </div>
                                <div className="shadow sm:rounded-lg">
                                    <table className="table-fixed min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="w-2/12 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    <div className="flex items-center space-x-2">
                                                        <span>Name</span>
                                                        <Dropdown
                                                            title={
                                                                <SelectorIcon className="h-4 w-4 currentColor" />
                                                            }
                                                            options={[
                                                                'Ascending',
                                                                'Descending',
                                                            ]}
                                                            onOptionSelect={(
                                                                option
                                                            ) => {
                                                                let sortedReports;
                                                                if (
                                                                    option ===
                                                                    'Ascending'
                                                                ) {
                                                                    sortedReports = [
                                                                        ...reports,
                                                                    ].sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.name
                                                                                .toLowerCase()
                                                                                .localeCompare(
                                                                                    b.name.toLowerCase()
                                                                                )
                                                                    );
                                                                } else {
                                                                    sortedReports = [
                                                                        ...reports,
                                                                    ].sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            b.name
                                                                                .toLowerCase()
                                                                                .localeCompare(
                                                                                    a.name.toLowerCase()
                                                                                )
                                                                    );
                                                                }
                                                                setReports(
                                                                    sortedReports
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-2/12 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider space-x-2"
                                                >
                                                    <div className="flex items-center space-x-2">
                                                        <span>Date</span>
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-2/12 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider space-x-2"
                                                >
                                                    <div className="flex items-center space-x-2">
                                                        <span>Type</span>
                                                        <Dropdown
                                                            title={
                                                                <FilterIcon className="h-4 w-4 currentColor" />
                                                            }
                                                            options={[
                                                                'All',
                                                                'Blood Sugar report',
                                                                'Urine analysis',
                                                                'Biochemistry',
                                                                'Haematology',
                                                                'Thyroid profile',
                                                                'Other',
                                                            ]}
                                                            onOptionSelect={(
                                                                option
                                                            ) => {
                                                                let filteredReports;
                                                                const type =
                                                                    Object.keys(
                                                                        reportType
                                                                    ).find(
                                                                        (key) =>
                                                                            reportType[
                                                                                key
                                                                            ] ===
                                                                            option
                                                                    ) || 'all';
                                                                if (
                                                                    type !==
                                                                    'all'
                                                                ) {
                                                                    filteredReports = [
                                                                        ...originalReports,
                                                                    ].filter(
                                                                        (
                                                                            report
                                                                        ) =>
                                                                            report.type ===
                                                                            type
                                                                    );
                                                                    setReports(
                                                                        filteredReports
                                                                    );
                                                                } else {
                                                                    setReports(
                                                                        originalReports
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-2/12 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Lab No
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-2/12 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Mobile Number
                                                </th>
                                                <th className="w-1/12 w- px-6 py-3 bg-gray-50">
                                                    <span className="sr-only">
                                                        Delete
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {reports.map((report) => (
                                                <tr
                                                    key={report._id}
                                                    className="hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition cursor-pointer"
                                                    onClick={() =>
                                                        history.push({
                                                            pathname: `/report/${report.type}/${report._id}`,
                                                            state: report,
                                                        })
                                                    }
                                                >
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium capitalize text-gray-900">
                                                        {report.name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {!!report.date &&
                                                            new Date(
                                                                report.date
                                                            ).toLocaleDateString(
                                                                'en-GB'
                                                            )}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                                        {!!report.type &&
                                                            reportType[
                                                                report.type
                                                            ]}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                                        {report.labNo}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                                        {report.mobile}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-center">
                                                        <span
                                                            className="text-red-300 hover:text-red-600"
                                                            onClick={(e) =>
                                                                handleDeleteReport(
                                                                    e,
                                                                    report
                                                                )
                                                            }
                                                        >
                                                            <TrashIcon className="currentColor h-5 w-5" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full flex justify-center items-center">
                                    {navButtons}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </MainLayout>
            <Modal
                isShowing={isShowingTypes}
                hide={toggleTypes}
                modalTitle={chooseTypeModal.modalTitle}
                modalContent={chooseTypeModal.modalContent}
            />
            <Modal
                isShowing={isShowingDelete}
                hide={toggleDelete}
                modalTitle={deleteReportModal.modalTitle}
                modalContent={deleteReportModal.modalContent}
            />
        </>
    );
};

export default Home;
