import React from 'react';
import { useAuth } from '../context/auth';
import { ChevronDownIcon } from '../utils';
import { Dropdown } from '../components';

const MainLayout = ({ children }) => {
    const { authTokens, removeAuthTokens } = useAuth();

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="print-hide-field bg-blue-800 pb-32">
                <header className="py-10">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                        <h1 className="text-4xl font-extrabold text-white lg:text-5xl">
                            Kumar Clinic
                        </h1>
                        <Dropdown
                            customButton={
                                <button
                                    className="max-w-xs bg-blue-800 flex items-center text-sm focus:outline-none rounded-md space-x-2"
                                    id="user-menu"
                                    aria-haspopup="true"
                                >
                                    <span className="text-white text-sm sm:text-base font-medium block truncate max-w-full capitalize">
                                        {authTokens.user.email}
                                    </span>
                                    <ChevronDownIcon className="flex-shrink-0 h-4 w-4 text-gray-200 block" />
                                </button>
                            }
                            options={['Logout']}
                            onOptionSelect={(option) => {
                                if (option === 'Logout') {
                                    removeAuthTokens();
                                }
                            }}
                            position="right"
                            width="36"
                        />
                    </div>
                </header>
            </div>
            <main className="-mt-32 print-mt-0">
                <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default MainLayout;
