import React, { useState, useRef } from 'react';
import { Transition } from '../components';
import { useOnClickOutside, CheckIcon } from '../utils';

const Dropdown = ({
    title,
    options,
    onOptionSelect,
    position = 'left',
    customButton = false,
    width = '52',
}) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState('');

    useOnClickOutside(ref, () => setIsOpen(false));

    return (
        <div className="relative" ref={ref}>
            {customButton ? (
                <div onClick={() => setIsOpen(!isOpen)}>{customButton}</div>
            ) : (
                <button
                    className="inline-flex items-center px-1 py-1 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {title}
                </button>
            )}
            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div
                    className={`absolute ${position}-0 mt-2 w-${width} rounded-md shadow-md z-30 ring-1 ring-black ring-opacity-5`}
                >
                    <div className="rounded-md bg-white shadow-xs">
                        <div
                            className="py-1"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            {options.map((option, index) => (
                                <div
                                    key={option + index}
                                    className={`space-x-2 normal-case text-left font-normal cursor-pointer px-3 py-2 text-sm text-gray-900  hover:font-normal hover:bg-blue-600 hover:text-white flex items-center justify-between ${
                                        selected === option
                                            ? 'font-semibold'
                                            : ''
                                    }`}
                                    onClick={(e) => {
                                        onOptionSelect(option);
                                        setSelected(option);
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    <span>{option}</span>
                                    {!!(selected === option) && (
                                        <CheckIcon className="text-blue-600 h-5 w-5" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
};

export default Dropdown;
