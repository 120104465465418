import React from 'react';
import {
    XIcon,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon,
} from '../utils';

const Toast = ({ appearance, title, onDismiss }) => {
    let color, icon;
    switch (appearance) {
        case 'success':
            color = 'green';
            icon = (
                <CheckCircleIcon
                    className={`h-6 w-6 mr-2 currentColor text-${color}-400`}
                />
            );
            break;
        case 'error':
            color = 'red';
            icon = (
                <XCircleIcon
                    className={`h-6 w-6 mr-2 currentColor text-${color}-400`}
                />
            );
            break;
        case 'info':
            color = 'blue';
            icon = (
                <ExclamationCircleIcon
                    className={`h-6 w-6 mr-2 currentColor text-${color}-400`}
                />
            );
            break;
        default:
            break;
    }

    return (
        <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
            <div
                className={`max-w-sm w-full bg-${color}-50 shadow-sm rounded-lg pointer-events-auto`}
            >
                <div className="rounded-lg overflow-hidden">
                    <div className="p-3">
                        <div className="flex items-center">
                            <div className="w-0 flex-1 flex justify-between">
                                {icon}
                                <span
                                    className={`w-0 flex-1 text-sm font-medium text-${color}-800`}
                                >
                                    {title}
                                </span>
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    className={`inline-flex text-${color}-500 focus:outline-none focus:text-gray-500 transition`}
                                    onClick={onDismiss}
                                >
                                    <XIcon className="h-5 w-5 currentColor" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toast;
