import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { FormLayout } from '../layouts';
import { FormTextInput } from '../components';
import { url } from '../constants/report';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';
import { useToasts } from 'react-toast-notifications';

const Register = () => {
    const { setAuthTokens } = useAuth();
    const history = useHistory();
    const { addToast } = useToasts();

    const handleRegister = async (values) => {
        const { email, password, confirmPassword } = values;

        if (password !== confirmPassword) {
            addToast('', {
                appearance: 'error',
                title: "Passwords don't match!",
            });
            return;
        }
        try {
            const response = await fetch(`${url}/api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: {
                        email,
                        password,
                    },
                }),
            });
            const result = await response.json();
            if (!result) {
                addToast('', {
                    appearance: 'error',
                    title: 'Failed to sign-up!',
                });
                return;
            }
            setAuthTokens(result);
            history.push('/');
        } catch (err) {
            addToast('', {
                appearance: 'error',
                title: 'Something went wrong! Please try again.',
            });
            console.error(err);
        }
    };

    return (
        <FormLayout>
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Create an account
                </h2>
                <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                email: '',
                                password: '',
                                confirmPassword: '',
                            }}
                            validationSchema={object({
                                email: string().required(
                                    'This field is required'
                                ),
                                password: string().required(
                                    'This field is required'
                                ),
                                confirmPassword: string().required(
                                    'This field is required'
                                ),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                handleRegister(values);
                                setSubmitting(false);
                            }}
                        >
                            <Form>
                                <FormTextInput
                                    label="Username"
                                    name="email"
                                    type="text"
                                />
                                <FormTextInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    className="mt-4"
                                />
                                <FormTextInput
                                    label="Confirm password"
                                    name="confirmPassword"
                                    type="password"
                                    className="mt-4"
                                />
                                <div className="mt-6">
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
                                    >
                                        Sign up
                                    </button>
                                    <div className="text-sm mt-1">
                                        <a
                                            href="/login"
                                            className="ml-1 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition"
                                        >
                                            Already have an account?
                                        </a>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </FormLayout>
    );
};

export default Register;
