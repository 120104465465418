import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { FormLayout } from '../layouts';
import { FormTextInput } from '../components';
import { url } from '../constants/report';
import { string, object } from 'yup';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';

const Login = () => {
    const { setAuthTokens } = useAuth();
    const history = useHistory();
    const { addToast } = useToasts();

    const handleLogin = async (values) => {
        const { email, password } = values;

        try {
            const response = await fetch(`${url}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: {
                        email: email,
                        password: password,
                    },
                }),
            });

            const result = await response.json();
            if (!result || !result.user) {
                addToast('', {
                    appearance: 'error',
                    title: 'Incorrect username or password!',
                });
                return;
            }
            setAuthTokens(result);
            history.push('/');
        } catch (err) {
            addToast('', {
                appearance: 'error',
                title: 'Something went wrong! Please try again.',
            });
            console.error(err);
        }
    };

    return (
        <FormLayout>
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Sign in to your account
                </h2>
                <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik
                            enableReinitialize
                            initialValues={{ email: '', password: '' }}
                            validationSchema={object({
                                email: string().required(
                                    'This field is required'
                                ),
                                password: string().required(
                                    'This field is required'
                                ),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                handleLogin(values);
                                setSubmitting(false);
                            }}
                        >
                            <Form>
                                <FormTextInput
                                    label="Username"
                                    name="email"
                                    type="text"
                                />
                                <FormTextInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    className="mt-4"
                                />
                                <div className="mt-6">
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
                                    >
                                        Sign in
                                    </button>
                                    <div className="text-sm mt-1">
                                        <span
                                            href="/signup"
                                            className="block text-sm text-gray-900"
                                        >
                                            Need an account?
                                            <a
                                                href="/signup"
                                                className="ml-1 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition"
                                            >
                                                Register
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </FormLayout>
    );
};

export default Login;
