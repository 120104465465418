import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Login, Register, Home, Report } from './pages';
import { Toast } from './components';
import { ToastProvider } from 'react-toast-notifications';
import { AuthContext } from './context/auth';
import './App.css';

function App() {
    const existingTokens = JSON.parse(localStorage.getItem('tokens'));
    const [authTokens, setAuthTokens] = useState(existingTokens);
    const setTokens = (data) => {
        localStorage.setItem('tokens', JSON.stringify(data));
        setAuthTokens(data);
    };
    const removeTokens = () => {
        localStorage.removeItem('tokens');
        setAuthTokens(null);
    };

    return (
        <AuthContext.Provider
            value={{
                authTokens,
                setAuthTokens: setTokens,
                removeAuthTokens: removeTokens,
            }}
        >
            <Router>
                <ToastProvider autoDismiss components={{ Toast: Toast }}>
                    <Switch>
                        <Route path="/report/:type/:reportId">
                            {authTokens ? <Report /> : <Login />}
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/signup">
                            <Register />
                        </Route>
                        <Route path="/">
                            {authTokens ? <Home /> : <Login />}
                        </Route>
                    </Switch>
                </ToastProvider>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;
