export const reportType = {
    'blood-report': 'Blood Sugar report',
    'urine-analysis': 'Urine analysis',
    biochemistry: 'Biochemistry',
    haematology: 'Haematology',
    'thyroid-profile': 'Thyroid profile',
    other: 'Other',
};

export const url = '';

export const normalValues = {
    'blood-report': {
        fbs: { low: '70', high: '100' },
        ppbs: { low: '80', high: '140' },
        rbs: { low: '80', high: '140' },
        hba1c: { low: '4.3', high: '6.3' },
    },
    'urine-analysis': {
        specificGravity: { low: '1.001', high: '1.035' },
        rbc: { low: '1', high: '3' },
        pusCells: { low: '1', high: '5' },
        epiCells: { low: '1', high: '5' },
    },
    biochemistry: {
        burea: { low: '10', high: '40' },
        creatinine: { low: '0.6', high: '1.4' },
        uricacid: {
            m: { low: '3.6', high: '7.7' },
            f: { low: '2.5', high: '6.8' },
        },
        cho: { low: '0', high: '250' },
        hdl: { m: { low: '30', high: '80' }, f: { low: '40', high: '80' } },
        ldl: { low: '60', high: '130' },
        vldl: { low: '5', high: '40' },
        tri: { low: '0', high: '150' },
        bil: { low: '0', high: '1' },
        dirbil: { low: '0', high: '0.25' },
        indbil: { low: '0', high: '0.75' },
        sgot: { low: '0', high: '40' },
        sgpt: { low: '0', high: '40' },
        alk: { low: '30', high: '140' },
        prot: { low: '6.0', high: '8.3' },
        albumin: { low: '3.7', high: '5.3' },
        globulin: { low: '1.8', high: '3.6' },
        ag: { low: '1.0', high: '2.5' },
        crp: { low: '0', high: '6' },
        aso: { low: '0', high: '200' },
        ra: { low: '0', high: '20' },
    },
    haematology: {
        hb: { m: { low: '12.5', high: '18' }, f: { low: '11.5', high: '16' } },
        pcv: { low: '34', high: '54' },
        rbc: { m: { low: '4.5', high: '6.5' }, f: { low: '4', high: '6' } },
        totalwbc: { low: '5000', high: '10000' },
        neutrophils: { low: '44', high: '73' },
        lymphocytes: { low: '19', high: '48' },
        eosinophils: { low: '1', high: '6' },
        basophils: { low: '0', high: '1' },
        monocytes: { low: '2', high: '10' },
        mcv: { low: '84', high: '98' },
        mch: { low: '28', high: '32' },
        mchc: { low: '32', high: '34' },
        esr: { m: { low: '0', high: '9' }, f: { low: '0', high: '20' } },
        plateletcount: { low: '150000', high: '400000' },
        bt: { low: '2', high: '7' },
        ct: { low: '6', high: '10' },
        aec: { low: '40', high: '400' },
        fbs: { low: '70', high: '100' },
        ppbs: { low: '80', high: '140' },
        rbs: { low: '80', high: '140' },
        hba1c: { low: '4.3', high: '6.3' },
    },
    'thyroid-profile': {
        t3: { low: '0.40', high: '1.81' },
        t4: { low: '5.74', high: '13.03' },
        tsh: { low: '0.550', high: '4.780' },
    },
};

export const reportData = {
    'blood-report': [
        {
            title: 'Blood Sugar Report',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['fbs', 'FBS', '70 - 100 mg/dl'],
                    ['ppbs', 'PPBS', '80 - 140 mg/dl'],
                    ['rbs', 'RBS', '80 - 140 mg/dl'],
                    [
                        'hba1c',
                        'HbA1c',
                        'Non-diabetic: 4.3 - 6.3% | Good control: 6.4 - 7.5% | Moderate control: 7.5 - 9.0% | Poor control: 9% and above',
                    ],
                    ['mbg', 'MBG', ''],
                ],
            },
        },
    ],
    'urine-analysis': [
        {
            title: 'Urine Analysis',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['colour', 'Colour', ''],
                    ['appearance', 'Appearance', ''],
                    ['reaction', 'Reaction', ''],
                    ['specificGravity', 'Specific Gravity', '1.001 - 1.035'],
                    ['albumin', 'Albumin', ''],
                    ['sugar', 'Sugar', ''],
                ],
            },
        },
        {
            title: 'Microscopic Examination',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['rbc', 'RBC', '1 - 3/hpf'],
                    ['pusCells', 'Pus cells', '1 - 5/hpf'],
                    ['casts', 'Casts', ''],
                    ['crystals', 'Crystals', ''],
                    ['epiCells', 'Epi cells', '1 - 5/hpf'],
                    ['bacteria', 'Bacteria', ''],
                ],
            },
        },
        {
            title: 'Chemical Examination',
            table: {
                titles: ['Investigations', 'Result'],
                rows: [
                    ['ketone', 'Ketone Bodies'],
                    ['urobilinogen', 'Urobilinogen'],
                    ['bileSalt', 'Bile Salt'],
                    ['bilePigment', 'Bile Pigment'],
                ],
            },
        },
        {
            title: '',
            table: {
                titles: ['Investigation', 'Result'],
                rows: [['pregnancy', 'Urine Pregnancy Test']],
            },
        },
    ],
    biochemistry: [
        {
            title: 'Biochemistry',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['burea', 'Blood urea', '10 - 40 mg/dl'],
                    ['creatinine', 'Creatinine', '0.6 - 1.4 mg/dl'],
                    [
                        'uricacid',
                        'Uric acid',
                        'M: 3.6 - 7.7 mg/dl | F: 2.5 - 6.8 mg/dl',
                    ],
                ],
            },
        },
        {
            title: 'Lipid Profile',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['cho', 'Total Cholesterol', 'Upto 250 mg/dl'],
                    [
                        'hdl',
                        'HDL Cholesterol',
                        'M: 30 - 80 mg/dl | F: 40 - 80 mg/dl',
                    ],
                    ['ldl', 'LDL Cholesterol', '60 - 130 mg/dl'],
                    ['vldl', 'VLDL Cholesterol', '5 - 40 mg/dl'],
                    ['tri', 'Triglyceride', 'Upto 150 mg/dl'],
                ],
            },
        },
        {
            title: 'Liver Function Tests',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['bil', 'Bilirubin - Total', '< 1.0 mg/dl'],
                    ['dirbil', 'Bilirubin - Direct', '< 0.25 mg/dl'],
                    ['indbil', 'Bilirubin - Indirect', '< 0.75 mg/dl'],
                    ['sgot', 'SGOT', 'Upto 40 IU/L'],
                    ['sgpt', 'SGPT', 'Upto 40 IU/L'],
                    ['alk', 'Alk. Phosphatase', '30 - 140 IU/l'],
                    ['prot', 'S Proteins - Total', '6.0 - 8.3 gm/dl'],
                    ['albumin', 'Albumin', '3.7 - 5.3 gm/dl'],
                    ['globulin', 'Globulin', '1.8 - 3.6 gm/dl'],
                    ['ag', 'A/G', '1.0 - 2.5 gm/dl'],
                ],
            },
        },
        {
            title: 'Miscellaneous',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['vdrl', 'VDRL', ''],
                    ['crp', 'CRP', '0 - 6 mg/L'],
                    ['aso', 'ASO', '< 200 IU/ml'],
                    ['ra', 'RA', '0 - 20 IU/L'],
                    ['hbsag', 'HBs Ag', ''],
                ],
            },
        },
        {
            title: 'HIV',
            table: {
                titles: ['Investigations', 'Result'],
                rows: [['hiv', 'HIV - SPOT']],
            },
        },
        {
            title: '',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['hiv1', '', ''],
                    ['hiv2', '', ''],
                    ['hiv3', '', ''],
                    ['hiv4', '', ''],
                    ['hiv5', '', ''],
                ],
            },
        },
    ],
    haematology: [
        {
            title: 'haematology',
            table: {
                titles: ['Investigations', 'Normal value', 'Result'],
                rows: [
                    ['hb', 'Hb', 'M: 12.5 - 18 gms/dl | F: 11.5 - 16.0 gms/dl'],
                    ['pcv', 'PCV', '34% - 54%'],
                    [
                        'rbc',
                        'RBC',
                        'M: 4.5 - 6.5 mill/cu/mm | F: 4.0 - 6.0 mill/cu/mm',
                    ],
                    ['totalwbc', 'Total WBC', '5000 - 10000 cu/mm'],
                    ['neutrophils', 'Neutrophils', '44% - 73%'],
                    ['lymphocytes', 'Lymphocytes', '19% - 48%'],
                    ['eosinophils', 'Eosinophils', '1 - 6%'],
                    ['basophils', 'Basophils', '0 - 1%'],
                    ['monocytes', 'Monocytes', '2% - 10%'],
                    ['mcv', 'MCV', '84 - 98fl'],
                    ['mch', 'MCH', '28 - 32Pg'],
                    ['mchc', 'MCHC', '32 - 34 g/dl'],
                    ['esr', 'ESR', 'M: 0 - 9 mm/l*hr | F: 0 - 20 mm/l*hr'],
                    [
                        'plateletcount',
                        'Platelet count',
                        '1,50,000 - 4,00,000 cu/mm',
                    ],
                    ['bt', 'BT', '2 - 7min'],
                    ['ct', 'CT', '6 - 10 at 37°C min'],
                    ['pt', 'PT', 'Patient'],
                    ['aec', 'AEC', '40 - 400'],
                ],
            },
        },
        {
            title: 'Blood group',
            table: {
                titles: ['Investigations', 'Result'],
                rows: [
                    ['abo', 'ABO Group'],
                    ['rhd', 'Rh (D)'],
                ],
            },
        },
        {
            title: 'Blood Sugar',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['fbs', 'FBS', '70 - 100 mg/dl'],
                    ['ppbs', 'PPBS', '80-140 mg/dl'],
                    ['rbs', 'RBS', '80 - 140 mg/dl'],
                    [
                        'hba1c',
                        'HbA1c',
                        'Non-diabetic: 4.3 - 6.3% | Good control: 6.4 - 7.5% | Moderate control: 7.5 - 9.0% | Poor control: 9% and above',
                    ],
                ],
            },
        },
        {
            title: 'Malarial Parasites',
            table: {
                titles: ['Investigations', 'Result'],
                rows: [
                    ['vivax', 'Plasmodium vivax'],
                    ['falciform', 'Plasmodium falciparum'],
                ],
            },
        },
        {
            title: 'Widal Slide Test',
            table: {
                titles: ['Investigations', 'Normal Value', 'Result'],
                rows: [
                    ['styphio', 'S Typhi O', ''],
                    ['styphih', 'S Typhi H', ''],
                    ['sparatyphiah', 'S Paratyphi AH', ''],
                    ['sparatyphibh', 'S Paratyphi BH', ''],
                ],
            },
        },
        {
            title: 'Dengue Test',
            table: {
                titles: ['Investigations', 'Result'],
                rows: [
                    ['ns1ag', 'Ns1Ag'],
                    ['igm', 'IgM'],
                    ['igg', 'IgG'],
                ],
            },
        },
    ],
    'thyroid-profile': [
        {
            title: 'Thyroid report',
            table: {
                titles: [
                    'Investigations',
                    'Units',
                    'Bio. Ref. Interval',
                    'Result',
                ],
                rows: [
                    ['t3', 'T3, Total', 'ng/mL', '0.40 - 1.81'],
                    ['t4', 'T4, Total', 'µg/dL', '5.74 - 13.03'],
                    ['tsh', 'TSH', 'µlU/dL', '0.550 - 4.780'],
                ],
            },
        },
    ],
};
