import React from 'react';

const Pattern = () => (
    <>
        <svg
            className="absolute left-full transform translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
        >
            <defs>
                <pattern
                    id="85737c0e-0916-41d7-917f-596dc7edfa27"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                >
                    <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-blue-200"
                        fill="currentColor"
                    />
                </pattern>
            </defs>
            <rect
                width={404}
                height={404}
                fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
        </svg>
        <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
        >
            <defs>
                <pattern
                    id="85737c0e-0916-41d7-917f-596dc7edfa27"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                >
                    <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-blue-200"
                        fill="currentColor"
                    />
                </pattern>
            </defs>
            <rect
                width={404}
                height={404}
                fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
        </svg>
    </>
);

const FormLayout = ({ children }) => (
    <div className="bg-gray-50 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="relative max-w-xl mx-auto">
            <Pattern />
            {children}
        </div>
    </div>
);

export default FormLayout;
