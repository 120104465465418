import React from 'react';
import { useField } from 'formik';

const FormTextInput = ({ label, className, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className={`col-span-6 sm:col-span-4 ${className}`}>
            <label
                htmlFor={props.id || props.name}
                className={`block text-sm font-medium text-gray-700 ${
                    meta.touched && meta.error ? 'text-red-500' : ''
                }`}
            >
                {label}
                {meta.touched && meta.error ? (
                    <span className="italic text-xs font-normal error mt-1">
                        &nbsp; - {meta.error}
                    </span>
                ) : null}
            </label>
            <input
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                {...field}
                {...props}
            />
        </div>
    );
};

export default FormTextInput;
