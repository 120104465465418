import React, { useState, useEffect, useRef, PureComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { MainLayout } from '../layouts';
import { url, reportType, reportData, normalValues } from '../constants/report';
import { ChevronRightIcon, PhoneIcon } from '../utils';
import { LoadingIndicator } from '../components';
import { useToasts } from 'react-toast-notifications';

const FormHeader = () => (
    <div className="max-w-screen-xl mx-auto">
        <div className="text-center max-w-max mx-auto p-1 border-4 border-red-700 mt-9">
            <div className="font-extrabold text-red-700 text-5xl leading-none">
                Kumar Clinic
            </div>
            <div className="max-w-xl mx-auto font-bold text-xl leading-none text-red-500">
                <span>Main road, Kateel - 574148, Mangalore taluk</span>
            </div>
            <div className="mt-1 max-w-xl mx-auto font-bold text-xl leading-none text-red-500 flex items-center justify-center space-x-2">
                <PhoneIcon className="h-5 w-5 leading-none" />
                <span className="leading-none">+(91) 7760350335</span>
            </div>
        </div>
        <h2 className="mt-1 font-extrabold text-gray-900 text-3xl text-center max-w-max mx-auto p-1 border-b-4 border-black uppercase">
            Lab Investigations
        </h2>
    </div>
);

const patientInputs = [
    { title: 'name', type: 'text', columns: 2 },
    { title: 'age', type: 'number', columns: 1 },
    { title: 'M/F', type: 'select', options: ['male', 'female'], columns: 1 },
    { title: 'labNo', type: 'text', columns: 2 },
    { title: 'mobile', type: 'text', columns: 2 },
    { title: 'date', type: 'date', columns: 2 },
    { title: 'doctor', type: 'text', columns: 2 },
];

const convertDate = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
};

const PatientInfo = ({ labReport, handleInputChange }) => {
    return (
        <div className="mt-1 pt-2 grid gap-y-1 gap-x-4 grid-cols-6 overflow-x-auto">
            {patientInputs.map((input) => (
                <div className={'col-span-' + input.columns} key={input.title}>
                    {input.type === 'select' ? (
                        <label className="grid grid-cols-7 space-x-1 items-center w-full">
                            <span className="col-span-3 text-gray-700 font-bold text-lg font-mono capitalize">
                                {input.title}
                            </span>
                            <select
                                name={input.title}
                                value={labReport[input.title] || ''}
                                onChange={(e) =>
                                    handleInputChange(e, 'patient')
                                }
                                className="col-span-4 appearance-none block w-auto capitalize font-mono font-semibold border-2 border-black px-1 py-0 rounded-none focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                            >
                                {input.options.map((option) => (
                                    <option
                                        key={option}
                                        className="capitalize"
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </label>
                    ) : (
                        <label className="grid grid-cols-7 space-x-2 items-center">
                            <span className="col-span-2 text-gray-700 font-bold text-lg font-mono capitalize">
                                {input.title}
                            </span>
                            {input.title === 'date' && (
                                <input
                                    name={input.title}
                                    value={convertDate(
                                        new Date(labReport[input.title]) ||
                                            new Date()
                                    )}
                                    onChange={(e) =>
                                        handleInputChange(e, 'patient')
                                    }
                                    id={input.title}
                                    type={input.type}
                                    className="col-span-5 appearance-none block w-auto font-mono font-semibold border-2 border-black px-1 py-0 rounded-none focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                />
                            )}
                            {input.title === 'doctor' ? (
                                <input
                                    name={input.title}
                                    value={
                                        labReport[input.title] ||
                                        'Dr Shashi Kumar'
                                    }
                                    onChange={(e) =>
                                        handleInputChange(e, 'patient')
                                    }
                                    id={input.title}
                                    type={input.type}
                                    className="col-span-5 appearance-none block w-auto font-mono font-semibold border-2 border-black px-1 py-0 rounded-none focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                />
                            ) : (
                                input.title !== 'date' && (
                                    <input
                                        name={input.title}
                                        value={labReport[input.title] || ''}
                                        onChange={(e) =>
                                            handleInputChange(e, 'patient')
                                        }
                                        id={input.title}
                                        type={input.type}
                                        className="col-span-5 appearance-none block w-auto font-mono font-semibold border-2 border-black px-1 py-0 rounded-none focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                )
                            )}
                        </label>
                    )}
                </div>
            ))}
        </div>
    );
};

const LabInvestigations = ({
    data,
    handleInputChange,
    labReport,
    abnormalValues,
}) => {
    return (
        <>
            {data.map((report, index) => (
                <div
                    className={`${index === 0 ? 'mt-0' : 'mt-2'} ${
                        Object.keys(
                            labReport?.report ? labReport?.report : {}
                        ).filter((id) => labReport?.report[id] !== '').length >
                        24
                            ? report.title === 'Widal Slide Test' ||
                              report.title === 'HIV'
                                ? 'pagebreak mt-4'
                                : ''
                            : ''
                    } ${
                        labReport?.report
                            ? !!report.table.rows
                                  .map((row) => row[0])
                                  .filter((value) =>
                                      Object.keys(labReport?.report)
                                          .filter(
                                              (id) =>
                                                  labReport?.report[id] !== ''
                                          )
                                          .includes(value)
                                  ).length
                                ? ''
                                : 'print-hide-field'
                            : 'print-hide-field'
                    }`}
                    key={report.title}
                >
                    <div
                        className={`text-2xl leading-6 font-bold text-red-700 mx-auto uppercase max-w-max px-1 ${
                            report.title === ''
                                ? ''
                                : 'border-b-4 border-red-700'
                        }`}
                    >
                        {report.title}
                    </div>
                    <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="border-b border-gray-200 sm:rounded-lg">
                                    <table className="table-fixed min-w-full max-w-5xl w-full divide-y-2 divide-black border-2 border-black mt-1 overflow-x-auto">
                                        <thead>
                                            <tr>
                                                {report.table.titles.map(
                                                    (title, index) => (
                                                        <th
                                                            key={title}
                                                            className={`break-all w-2/6 px-2 py-1 bg-white text-left text-xl leading-none text-black font-mono ${
                                                                report.table
                                                                    .titles
                                                                    .length -
                                                                    2 ===
                                                                index
                                                                    ? 'border-r-2 border-black'
                                                                    : 'border-none'
                                                            } ${
                                                                report.table
                                                                    .titles
                                                                    .length ===
                                                                2
                                                                    ? index ===
                                                                          0 &&
                                                                      'w-4/6'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {title}
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-black">
                                            {report.table.rows.map((row) => (
                                                <tr
                                                    key={row}
                                                    className={`${
                                                        labReport?.report?.[
                                                            row[0]
                                                        ]
                                                            ? ''
                                                            : 'print-hide-field'
                                                    }`}
                                                >
                                                    <td
                                                        className={`px-2 break-words max-w-full font-mono leading-none text-gray-900 font-semibold text-xl ${
                                                            !!(
                                                                row[2] ||
                                                                row[2] === ''
                                                            )
                                                                ? ''
                                                                : 'border-r-2 border-black'
                                                        }`}
                                                    >
                                                        {row[1]}
                                                    </td>
                                                    {!!(
                                                        row[2] || row[2] === ''
                                                    ) && (
                                                        <td
                                                            className={`px-2 text-base font-mono leading-none text-gray-700 font-medium ${
                                                                !!(
                                                                    row[3] ||
                                                                    row[3] ===
                                                                        ''
                                                                )
                                                                    ? ''
                                                                    : 'border-r-2 border-black'
                                                            }`}
                                                        >
                                                            {row[2]}
                                                        </td>
                                                    )}
                                                    {!!(
                                                        row[3] || row[3] === ''
                                                    ) && (
                                                        <td className="px-2 whitespace-nowrap text-base font-mono leading-none text-gray-700 font-medium border-r-2 border-black">
                                                            {row[3]}
                                                        </td>
                                                    )}
                                                    <td className="whitespace-nowrap text-right text-base leading-none font-medium font-mono">
                                                        <input
                                                            type="text"
                                                            name={row[0]}
                                                            value={
                                                                labReport
                                                                    ?.report?.[
                                                                    row[0]
                                                                ]
                                                                    ? labReport
                                                                          .report[
                                                                          row[0]
                                                                      ]
                                                                    : ''
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'lab'
                                                                )
                                                            }
                                                            // className="form-input block w-full border-none p-0 rounded-none"
                                                            className={`appearance-none block w-full border-none p-0 px-2 rounded-none focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                                                abnormalValues.indexOf(
                                                                    row[0]
                                                                ) >= 0
                                                                    ? 'text-gray-900 font-extrabold tracking-widest text-lg'
                                                                    : ''
                                                            }`}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

const thyroidReferenceData = [
    ['1st trimester', '0.100 - 2.500'],
    ['2nd trimester', '0.200 - 3.000'],
    ['3rd trimester', '0.300 - 3.000'],
];

const AdditionalInfo = () => (
    <div className="mt-2">
        <div
            className={`text-xl leading-6 font-bold text-gray-900 mx-auto uppercase max-w-max px-1 border-b-4 border-gray-900`}
        >
            Note
        </div>
        <ul className="list-decimal mt-2 ml-3 whitespace-normal leading-none text-gray-900 font-semibold text-lg space-y-1">
            <li>
                TSH levels are subject to circadian variation, reaching peak
                levels between 2 - 4 A.M and at a minimum between 6 - 10 P.M.
                The variation is of the order of 50%. Hence time of the day has
                influence on the measured serum TSH concentrations.
            </li>
            <li>
                Alteration in concentration of Thyroid hormone binding protein
                can profoundly affect Total T3 and/or Total T4 levels especially
                in pregnancy and in patients on steroid therapy.
            </li>
            <li>
                Unbound fraction (Free, T4/Free, T3) of thyroid hormone is
                biologically active form and correlate more closely with
                clinical status of the patient than total T4/T3 concentration
            </li>
            <li>
                Values &lt; 0.03ulU/mL need to be clinically correlated due to
                presence of a rare TSH variant in some individuals.
            </li>
        </ul>
        <div
            className={`mt-2 text-xl leading-6 font-bold text-gray-900 mx-auto uppercase max-w-max px-1 border-b-4 border-gray-900`}
        >
            Interpretation
        </div>
        <table className="min-w-full divide-y-2 divide-black border-2 border-black mt-1">
            <thead>
                <tr>
                    <th
                        className={`px-2 py-1 bg-white text-left text-xl leading-none  text-black font-mono border-r-2 border-black `}
                    >
                        Pregnancy
                    </th>
                    <th
                        className={`px-2 py-1 bg-white text-left text-xl leading-none  text-black font-mono border-r-2 border-black `}
                    >
                        Reference range for TSH in uIu/mL (as per American
                        thyroid association)
                    </th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-black">
                {thyroidReferenceData.map((row) => (
                    <tr key={row}>
                        {row.map((data) => (
                            <td
                                key={data}
                                className={`px-2 whitespace-nowrap font-mono leading-none text-gray-900 font-semibold text-xl border-r-2 border-black
                                `}
                            >
                                {data}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const TextArea = ({ labReport, handleInputChange }) => (
    <div className="mt-4 w-full p-2 border-4 border-black">
        <textarea
            className="form-textarea mt-1 block w-full text-lg leading-none font-bold font-mono border-none overflow-hidden"
            rows={25}
            name="comments"
            value={labReport?.report?.comments ? labReport.report.comments : ''}
            onChange={(e) => handleInputChange(e, 'lab')}
        />
    </div>
);

const SignatureArea = () => (
    <div className="flex items-end justify-between font-extrabold text-gray-900 text-xl text-center h-20">
        <span>Signature of Doctor</span>
        <span>Signature of Technician</span>
    </div>
);

class ReportForm extends PureComponent {
    render() {
        const { type, report, handleFormInputs, abnormalValues } = this.props;

        const data = reportData[type];
        return (
            <div className="max-w-5xl mx-auto px-2 my-2">
                <FormHeader />
                <PatientInfo
                    labReport={report}
                    handleInputChange={handleFormInputs}
                />
                {type !== 'other' && (
                    <LabInvestigations
                        data={data}
                        labReport={report}
                        handleInputChange={handleFormInputs}
                        abnormalValues={abnormalValues}
                    />
                )}
                {type === 'other' && (
                    <TextArea
                        labReport={report}
                        handleInputChange={handleFormInputs}
                    />
                )}
                {!!(type === 'thyroid-profile') && <AdditionalInfo />}
                <SignatureArea />
            </div>
        );
    }
}

const initState = {
    name: '',
    age: 0,
    doctor: 'Dr Shashi Kumar',
    labNo: '',
    date: new Date(),
    gender: '',
    report: {},
};

const Report = () => {
    const { authTokens } = useAuth();
    const { type, reportId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [labReport, setLabReport] = useState(initState);
    const [abnormalValues, setAbnormalValues] = useState([]);
    const [dataValues, setDataValues] = useState([]);
    const componentRef = useRef();
    const { addToast } = useToasts();

    const handleFormInputs = (event, inputType) => {
        if (inputType === 'patient') {
            const newReport = { ...labReport };
            newReport[event.target.name] = event.target.value;
            setLabReport(Object.assign({}, labReport, newReport));
        } else {
            const newReport = { ...labReport.report };
            newReport[event.target.name] = event.target.value;
            let outOfRange = [...abnormalValues];
            if (checkRange(type, event.target.name, event.target.value))
                outOfRange =
                    outOfRange.indexOf(event.target.name) >= 0
                        ? outOfRange.filter((val) => {
                              return val !== event.target.name;
                          })
                        : outOfRange;
            else
                outOfRange.indexOf(event.target.name) === -1 &&
                    outOfRange.push(event.target.name);
            setAbnormalValues(outOfRange);
            setLabReport(Object.assign({}, labReport, { report: newReport }));
        }
    };

    const saveReport = async (noToast) => {
        labReport.type = type;
        if (!('report' in labReport)) {
            labReport['report'] = {};
        }
        const submitUrl = labReport._id
            ? `${url}/api/reports/${labReport._id}`
            : `${url}/api/reports`;
        try {
            const response = await fetch(submitUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + authTokens.user.token,
                },
                body: JSON.stringify(labReport),
            });
            const data = await response.json();
            if (!labReport._id) {
                setLabReport({ ...labReport, _id: data.data._id });
            }
            if (!noToast) {
                addToast('', {
                    appearance: 'success',
                    title: 'Report saved.',
                });
            }
        } catch (err) {
            addToast('', {
                appearance: 'error',
                title: 'Something went wrong! Please try again.',
            });
            console.error(err);
        }
    };

    const handlePrint = () => {
        window.print();
        saveReport(true);
    };

    function checkRange(title, valueName, value, gender) {
        if (!value) return true;
        if (!normalValues[title] || !normalValues[title][valueName])
            return true;
        if (value.indexOf('-') >= 0) {
            value = {
                low: value.split('-')[0],
                high: value.split(/[\s]*-[\s]*/)[1],
            };
            ['high', 'low'].map((param) => {
                value[param] = value[param] || '';
                value[param] =
                    value[param].search(/[a-zA-Z\s%\/]/) >= 0
                        ? value[param].substr(
                              0,
                              value[param].search(/[a-zA-Z\s%\/]/)
                          )
                        : value[param];
                value[param] = value[param].split(',').join('');
            });
        } else {
            value =
                value.search(/[a-zA-Z\s%]/) >= 0
                    ? value.substr(0, value.search(/[a-zA-Z\s%]/))
                    : value;
            value = value.split(',').join('');
        }
        console.log(value);
        let range = {};
        if (normalValues[title][valueName]['m']) {
            range = (
                gender
                    ? gender == 'male'
                    : !labReport['gender'] || labReport['gender'] == 'male'
            )
                ? normalValues[title][valueName]['m']
                : normalValues[title][valueName]['f'];
        } else {
            range = normalValues[title][valueName];
        }

        if (value['high'] || value['low']) {
            return (
                Number(value['high']) >= Number(range.low) &&
                Number(value['high']) <= Number(range.high) &&
                Number(value['low']) >= Number(range.low) &&
                Number(value['low']) <= Number(range.high)
            );
        } else {
            return (
                Number(value) >= Number(range.low) &&
                Number(value) <= Number(range.high)
            );
        }
    }

    useEffect(() => {
        const fetchReport = async () => {
            if (reportId === 'new') return;
            setIsLoading(true);
            try {
                const response = await fetch(`${url}/api/reports/${reportId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: 'Token ' + authTokens.user.token,
                    },
                });
                const result = await response.json();
                setLabReport(result.data);
                let data = [];
                reportData[result.data.type].map((sub) => {
                    sub.table.rows.map((row) => {
                        data.push(row[0]);
                    });
                });
                setDataValues(data);
                let outOfRange = data.filter((value) => {
                    if (!normalValues[result.data.type][value]) return false;
                    return !checkRange(
                        result.data.type,
                        value,
                        result.data.report[value],
                        result.data.gender
                    );
                });
                setAbnormalValues(outOfRange);
            } catch (err) {
                console.error(err);
            }
            setIsLoading(false);
        };
        fetchReport();
    }, [authTokens.user.token, reportId]);

    return (
        <MainLayout>
            <div className="max-w-none mx-auto">
                <div className="bg-white sm:rounded-lg sm:shadow">
                    <div className="print-hide-field bg-white px-4 py-4 border-b border-gray-200 sm:px-6 sticky top-0 sm:rounded-t-lg">
                        <div className="flex flex-col sm:flex-row items-center justify-between flex-wrap sm:flex-nowrap space-y-2 sm:space-y-0">
                            <nav
                                className="flex items-center justify-center"
                                aria-label="Breadcrumb"
                            >
                                <div className="flex items-center justify-center space-x-3 mt-2">
                                    <a
                                        href="/"
                                        className="text-gray-500 hover:text-blue-600 hover:underline focus:outline-none focus:text-blue-600 focus:underline"
                                    >
                                        <h2 className="text-xl font-bold sm:text-3xl sm:truncate transition pb-1">
                                            Home
                                        </h2>
                                    </a>
                                    <div className="flex items-center space-x-4 pb-1">
                                        <ChevronRightIcon className="h-4 w-4 sm:h-6 sm:w-6 currentColor" />
                                        <h2 className="text-xl font-bold text-gray-900 sm:text-3xl sm:truncate ">
                                            {reportType[type]}
                                        </h2>
                                    </div>
                                </div>
                            </nav>
                            <div className="flex items-center justify-center space-x-4">
                                <span className="inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => saveReport(false)}
                                        className="w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 transition"
                                    >
                                        Save
                                    </button>
                                </span>
                                <span className="inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        onClick={handlePrint}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 transition"
                                    >
                                        Print
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <LoadingIndicator />
                    ) : (
                        <ReportForm
                            ref={componentRef}
                            type={type}
                            report={labReport}
                            handleFormInputs={handleFormInputs}
                            abnormalValues={abnormalValues}
                        />
                    )}
                </div>
            </div>
        </MainLayout>
    );
};

export default Report;
